<template>
  <div>
    <a-page-header
      title="返回"
      sub-title="批量添加视频"
      @back="() => $router.go(-1)"
    />

    <a-row>
      <a-col :span="8">
        <a-form :label-col="{ span: 8 }" :wrapper-col="{ span: 12 }">
          <a-form-item label="选择企业">
           <CompanySelect
              style="width:200px"
              @CompanySelect="CompanySelect"
              :serchKeyword="undefined"
              :selectId="addFromData.company_id"
            ></CompanySelect>
          </a-form-item>
          <a-form-item label="选择栏目">
             <GroupSelect
              @GroupSelect="GroupSelect"
              :selectId="addFromData.group_id"
              style="width:200px"
            ></GroupSelect>
          </a-form-item>

            <a-form-item label="创建时间">
            <a-date-picker
              tyle="width:160px"
              format="YYYY-MM-DD HH:mm:ss"
              valueFormat="YYYY-MM-DD HH:mm:ss"
              show-time
              :locale="locale"
              v-model="addFromData.create_time"
            />
          </a-form-item>

          <a-form-item label="视频上传">
            <a-upload
              accept=".rm,.mp4,.m4v,.rmvb,.dvix,.3gp,.mov,.dat ,.mkv,.flv"
              action="https://up-cn-east-2.qiniup.com"
              :before-upload="beforeUploadVideo"
              :data="videoForm"
              :headers="headers"
              @change="handleVideoChange"
              :fileList="fileList"
            >
              <a-button type="primary">
                <a-icon type="upload" /> 视频上传
              </a-button>
            </a-upload>
          </a-form-item>
        </a-form>
      </a-col>
      <a-col :span="6">
        <a-form :label-col="{ span: 4 }" :wrapper-col="{ span: 12 }">
          <a-form-item label="点击量">
            <a-input-number v-model="addFromData.post_hits" style="width:160px" />
          </a-form-item>
          <a-form-item label="点赞量">
            <a-input-number v-model="addFromData.post_like" style="width:160px" />
          </a-form-item>
          <a-form-item label="收藏量">
            <a-input-number v-model="addFromData.post_favorites" style="width:160px" />
          </a-form-item>
          
          <a-form-item>
            <a-button
              type="primary"
              style="margin-left:100px"
              @click="handleOk"
            >
              保存
            </a-button></a-form-item
          >
        </a-form>
      </a-col>
      <a-col :span="8">
        <a-form :label-col="{ span: 4 }" :wrapper-col="{ span: 12 }">
          <a-form-item label="排序">
            <a-input-number :min='0' v-model="addFromData.list_order" style="width:100px" />
          </a-form-item>
          <a-form-item label="是否置顶">
            <a-radio-group v-model="addFromData.is_top">
              <a-radio :value="1">
                是
              </a-radio>
              <a-radio :value="0">
                否
              </a-radio>
            </a-radio-group>
          </a-form-item>
          <a-form-item label="是否推荐">
            <a-radio-group v-model="addFromData.recommended">
              <a-radio :value="1">
                是
              </a-radio>
              <a-radio :value="0">
                否
              </a-radio>
            </a-radio-group>
          </a-form-item>
        </a-form>
      </a-col>
    </a-row>
  </div>
</template>

<script>
import {
  getQiniuToken,
  directMoreSaveFile,
  videoBatchSave,
} from "@/apis/index";
import moment from "moment";
import locale from "ant-design-vue/es/date-picker/locale/zh_CN";
export default {
  data() {
    return {
      locale,
      addFromData: {
        video_ids: "",
        group_id: undefined,
        post_hits: "",
        post_favorites: "",
        post_like: "",
        list_order: "",
        is_top: 0,
        recommended: 0,
        company_id: undefined,
        create_time:''
      },
      fileList: [],
      videoLoading: false,
      headers: {
        token: "",
      },
      videoForm: {
        token: "",
        key: "",
      },
      params: {
        file_hashs: [],
        file_keys: [],
        origin_names: [],
      },
    };
  },
  created() {
    getQiniuToken().then((res) => {
      if (res.code == 1) {
        this.headers.token = res.data.uptoken;
        this.videoForm.token = res.data.uptoken;
      }
    });
  },

  methods: {
     CompanySelect(val) {
      this.addFromData.company_id = val;
    },
    GroupSelect(val) {
      this.addFromData.group_id = val;
    },
    handleOk() {
      if (
        this.addFromData.group_id &&
        this.addFromData.company_id &&
        this.fileList.length > 0
      ) {
        this.fileList.forEach((item) => {
          this.params.file_hashs.push(item.response.hash);
          this.params.file_keys.push(item.response.key);
          this.params.origin_names.push(
            item.name.substring(0, item.name.lastIndexOf("."))
          );
        });
        directMoreSaveFile(this.params).then((res) => {
          if (res.code == 1) {
            this.addFromData.video_ids = res.data.toString();
            videoBatchSave(this.addFromData).then((res) => {
              if (res.code == 1) {
                this.$message.success('视频存储成功')
                this.$router.push({ path: "/contents/video" });
              }
            });
          }
        });
      }else{
        this.$message.error('资料提交不全')
      }
    },

    handleVideoChange(info) {
      this.fileList = info.fileList;
      if (info.file.status === "uploading") {
        this.videoLoading = true;
      }
      // if (info.file.status === "done") {
      // }
      // if (info.file.status === "removed") {
      // }
    },
    beforeUploadVideo(file) {
      this.addFromData.video_time = "";
      //   this.loading = true;
      var file_ext = file.name.substr(file.name.lastIndexOf("."));
      var file_dir = "files/" + moment(new Date()).format("YYYYMMDD") + "/";
      var file_name =
        parseInt(new Date().valueOf() / 1000) +
        "-" +
        parseInt(Math.random() * 8999 + 1000) +
        file_ext;
      this.videoForm.key = file_dir + file_name;
      const isLt2M = file.size / 1024 / 1024 < 500;
      if (!isLt2M) {
        this.$message.error("上传视频大小不能超过 500MB!");
        // this.loading = false;
        return false;
      }
    },
  },
};
</script>

<style lang="less" scoped></style>
